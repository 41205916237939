<template>
  <div>
    <van-popup
      v-model:show="showPhone"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="user-phone-popup"
    >
      <div class="info-box">
        <div class="close-img" @click="showPhone = false">
          <img src="@/assets/img/user/home/close.png" />
        </div>
        <div class="code flex align-center flex-center">
          <p></p>
          消息中心
          <p></p>
        </div>
        <div class="center flex align-center">
          <p>关注微信公众号“灵龙惠生活”，通过微信随时接收会员、团长咨询</p>
          <p @click="copyFun">复制公众号名称</p>
        </div>
        <div class="pic">
          <img src="@/assets/img/loginRegister/qrcode.png" />
        </div>
        <p class="b-t">截图此二维码，用微信扫码关注企业客服</p>
        <div class="b-img flex flex-between">
          <div class="im">
            <img src="@/assets/img/user/home/m1.png" alt="" />
            <p>交易消息</p>
          </div>
          <div class="im">
            <img src="@/assets/img/user/home/edit.png" alt="" />
            <p>留言消息</p>
          </div>
          <div class="im">
            <img src="@/assets/img/user/home/news.png" alt="" />
            <p>互动消息</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import useClipboard from "vue-clipboard3"
import { inject } from 'vue'
export default {
  setup() {
    let showPhone = inject('showphone');
    return {
      showPhone
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    async copyFun() {
        try {
            const { toClipboard } = useClipboard()
            await toClipboard('灵龙惠生活')
            this.$toast.success("已复制公众号名称")
        } catch (e) {
            console.error(e)
        }
    }
  }
}
</script>
<style lang="scss">
.user-phone-popup {
    width: 280px !important;
    top: 155px !important;
    transform: translateX(-50%) !important;
    background: none !important;
    overflow-y: unset !important;
    .info-box {
      background: #ffffff;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      position: relative;
      padding: 20px 0 0;
      margin: 0 auto;
      position: relative;
      .close-img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -34px;
        right: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .code {
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        text-align: center;
        margin-bottom: 15px;
        p {
          background: #e62129;
          width: 8px;
          height: 1.5px;
        }
        p:nth-child(1) {
          margin-right: 5px;
        }
        p:nth-child(2) {
          margin-left: 5px;
        }
      }
      .center {
        background: #f6f6f6;
        padding: 10px 12px;
        p:nth-child(1) {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-right: 4px;
        }
        p:nth-child(2) {
          height: 24px;
          background: #e62129;
          -webkit-border-radius: 18px;
          border-radius: 18px;
          text-align: center;
          line-height: 24px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          flex-shrink: 0;
          margin-top: 4px;
          padding: 0 10px;
        }
      }
      .pic {
        text-align: center;
        margin-top: 15px;
        overflow: hidden;
        img {
          width: 180px;
          height: 180px;
          transform: scale(1.15);
        }
      }
      .b-t {
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin: 10px 0 15px 0;
        text-align: center;
      }
      .b-img {
        width: 174px;
        margin: 0 auto;
        .im {
          width: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
          }
          p {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #abb0bd;
            line-height: 17px;
            white-space: nowrap;
            margin: 8px 0 20px 0;
          }
        }
      }
    }
  }
</style>