<template>
  <div>
    <van-popup
      v-model:show="showSet"
      position="bottom"
      :style="{ width: '100%' }"
      :close-on-click-overlay="true"
      class="set-user-popup"
    >
     <div class="set-box">
         <p class="title">设置</p>
         <div class="s-li flex align-center" @click="updateFun">
             <img src="@/assets/img/user/home/update.png" class="left"/>
             <p>更改登录密码</p>
             <img src="@/assets/img/user/home/u-right.png" class="right"/>
         </div>
       <div class="s-li flex align-center" @click="updateRealNameFun">
         <img src="@/assets/img/user/home/update.png" class="left"/>
         <p>设置真实姓名</p>
         <img src="@/assets/img/user/home/u-right.png" class="right"/>
       </div>
         <p class="login-out" @click="loginOut">退出登录</p>
     </div> 
    </van-popup>
  </div>
</template>
<script>
import { inject } from "vue";
export default {
  setup() {
    let showSet = inject("showset");
    return {
      showSet,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {
      updateFun() {
        this.$router.push('/forgetPassword')
      },
      updateRealNameFun() {
        this.$router.push('/setRealName')
      },
      loginOut() {
        this.$cookies.remove('isLogin')
        this.$router.push('/login')
      }
  },
};
</script>
<style lang="scss">
.set-user-popup {
  width: 100% !important;
  top: unset !important;
  bottom: 0 !important;
  transform: unset !important;
  background: #FFFFFF !important;
  -webkit-border-radius: 10px 10px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  .set-box{
    width: 335px;
    height: 313px;
    padding: 15px 0 0;
    margin: 0 auto;
    position: relative;
    .login-out{
        position: absolute;
        text-align: center;
        left: 50%;
        bottom: 15px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 314px;
        height: 40px;
        background: #F6F6F6;
        -webkit-border-radius: 22px;
        border-radius: 22px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
    }
    .title{
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4A4A4A;
        line-height: 22px;
        text-align: center;
    }
    .s-li{
        height: 30px;
        margin-top: 32px;
        img{
            flex-shrink: 0;
        }
        .left{
            width: 30px;
            height: 30px;
            margin-right: 12px;
        }
        .right{
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
        p{
            flex-grow: 1;
            text-align: left;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
  }
}
</style>